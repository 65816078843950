<div data-bs-spy="scroll" data-bs-target="#navbar-scrollMovil" data-bs-root-margin="0px 0px -40%"
  data-bs-smooth-scroll="true" class="scrollspy-example bg-body-tertiary rounded-2" tabindex="0">

  <div *ngIf="this.router.url.includes('/PlanesIndividuales')">
    <section
      class="bg-gray p-3 d-flex flex-md-row flex-column align-items-center justify-content-center w-100 text-center gap-md-5 gap-2">

      <h2 class="fw-medium text-black fs-5 mb-0">Contrata paquetes adicionales iguales a tu plan por
        <span class="fw-bold">$5.990c/u</span>
      </h2>
    </section>
    <section>
      <div class="container mt-3 ">
        <div class="row d-flex justify-content-center">
          <div class="flex d-flex flex-column justify-content-center align-items-center mb-5 gap-3">
            <span class="text-black fs-4">¿Cúantos planes quieres contratar?</span>
            <div class="rounded-9  px-5 d-flex align-items-center gap-5 shadow">
              <button (click)="reduceQuantity()" class="border-0 fs-2  bg-transparent colorMovistar">-</button>
              <span class="fs-4 colorMovistar px-5 border-top-0 border-bottom-0 borderblue">{{cuantity}}</span>
              <button (click)="aumentQuantity()" class=" border-0 fs-2 bg-transparent colorMovistar">+</button>
            </div>
          </div>


          <div class="col-12 col-sm-4 col-md-3 col-lg-3 my-2" *ngFor="let product of productos">
            <app-caluga [type]="'movil'" [cuantity]="cuantity" [producto]="product"></app-caluga>
          </div>
        </div>
      </div>
    </section>


    <section class="container mt-5 py-4">

      <h2 class="fw-bold colorMovistar mb-4">Nuestros Planes incluyen</h2>

      <div class="row d-flex flex-column flex-md-row">
        <div class="col-12 col-md-4">
          <h4 class="text-black mb-2">Internet</h4>
          <div class="row">
            <div class="col-12 d-flex gap-4 align-items-center mb-4">
              <img src="../../../assets/img/Iconos Landing/Gigas.svg" height="40" width="40">
              <div>
                <span class="fw-bold text-black mb-0">Gigas Libres</span>
              </div>
            </div>
            <div class="col-12 d-flex gap-4 align-items-center mb-4">
              <img src="../../../assets/img/Iconos Landing/roaming.svg" height="40" width="40" fill="#00a9e0;">
              <div>
                <span class="fw-bold text-black mb-0">Roaming por 7 días desde</span>
                <p class="text-black mb-0 fs08">Plan 5G libre full</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section class="container mt-5 py-4 d-flex flex-column gap-3">

      <h1 class="colorMovistar fs-1 w-75">
        Descubre los increíbles planes 5G de Movistar Chile para una experiencia conectada única
      </h1>
      <p>
        Los planes 5G de Movistar ofrecen opciones variadas para disfrutar de alta velocidad y conectividad. Con gigas
        libres, minutos y SMS, así como promociones especiales y beneficios exclusivos, brindan una excelente
        experiencia. Desde el 5G Libre Inicia hasta el 5G Libre Ultra, los planes ofrecen diferentes capacidades de
        gigas y roaming ilimitado en ciertos países. Además, la portabilidad es sencilla y se mantiene el número. Con
        amplia cobertura en Chile, los planes 5G de Movistar son una elección de calidad.
      </p>

      <h2 class="colorMovistar fs-3 ">
        Planes 5G de Movistar
      </h2>
      <p>
        Los planes 5G de Movistar ofrecen a los usuarios una amplia gama de opciones para disfrutar de una conexión de
        alta velocidad y mejorar su experiencia de conectividad. Estos planes brindan la posibilidad de cambiar de
        compañía telefónica y aprovechar los beneficios de la red 5G.
      </p>
      <h2 class="colorMovistar fs-3 ">
        Opciones de planes 5G Movistar
      </h2>
      <p>
        Dentro de la oferta de planes 5G de Movistar, se encuentran diversas alternativas que se adaptan a las
        necesidades y preferencias de cada usuario. Desde el 5G Libre Inicia hasta el 5G Libre Ultra, hay opciones para
        todos los gustos. Estos planes incluyen una variedad de gigas disponibles para navegar a alta velocidad, así
        como minutos y SMS.
      </p>

      <h2 class="colorMovistar fs-3 ">
        Beneficios de los planes 5G Movistar
      </h2>
      <div>
        <p>
          Además de la velocidad y la conectividad mejorada, los planes 5G de Movistar ofrecen beneficios exclusivos
          para
          sus clientes. Estos incluyen promociones especiales, roaming ilimitado en ciertos países y otros beneficios
          adicionales que hacen que la experiencia sea aún más gratificante.
        </p>
        <ul>
          <li>
            Gigas libres para disfrutar de una navegación sin límites.
          </li>
          <li>
            Minutos y SMS incluidos en los planes para una comunicación completa.
          </li>
          <li>
            Roaming ilimitado en ciertos países para mantenerse conectado incluso cuando se viaja.
          </li>
          <li>
            Promociones especiales que ofrecen descuentos y ventajas exclusivas.
          </li>
          <li>
            Beneficios exclusivos para los clientes, brindando un trato especial y privilegios adicionales.
          </li>
        </ul>

        <p>
          Los planes 5G de Movistar permiten a los usuarios obtener una mayor velocidad y mejores opciones de
          conectividad, lo que los convierte en una excelente elección para aquellos que buscan una experiencia
          conectada de alta calidad.
        </p>
      </div>

      <h2 class="colorMovistar fs-3 ">
        Planes moviles de Movistar
      </h2>

      <h2 class="colorMovistar fs-3 ">
        Velocidad de navegación en los planes de móvil Movistar
      </h2>

      <p>
        Los planes de móvil de Movistar en Chile ofrecen una velocidad de navegación excepcional. Con la red 5G, los
        usuarios disfrutarán de una conexión rápida y estable que les permitirá acceder a contenido en línea, ver videos
        en alta definición y descargar archivos rápidamente. Ya sea que estés navegando por tus redes sociales
        favoritas, jugando en línea o realizando videollamadas, la velocidad de navegación de los planes de móvil de
        Movistar garantiza una experiencia fluida y sin interrupciones.
      </p>

      <h2 class="colorMovistar fs-3 ">
        Cobertura de los planes de móvil Movistar
      </h2>

      <p>
        La cobertura de los planes de móvil de Movistar en Chile es extensa y confiable. Ya sea que te encuentres en la
        ciudad, en áreas suburbanas o incluso en zonas rurales, podrás disfrutar de una conexión sólida y estable.
        Movistar cuenta con una amplia infraestructura de antenas y torres de transmisión, lo que garantiza una
        cobertura casi en todo el territorio chileno. Tendrás señal incluso en áreas remotas, lo que te permitirá estar
        siempre conectado y disfrutar de todos los beneficios de la red 5G sin preocuparte por la calidad de la señal.
      </p>

      <h2 class="colorMovistar fs-3 ">
        Portabilidad a Movistar
      </h2>
      <p>
        En Movistar, realizar la portabilidad a nuestra compañía es un proceso sencillo y conveniente. Si estás pensando
        en cambiar de operador y deseas disfrutar de todos los beneficios que ofrecen nuestros planes 5G, puedes
        conservar tu número actual sin ningún problema.
      </p>
      <p>
        Con la portabilidad a Movistar, no solo mantendrás tu número telefónico, sino que también podrás acceder a un
        nuevo plan con excelentes prestaciones y ventajas exclusivas. No tendrás que preocuparte por perder tu contacto
        con amigos, familiares o clientes, ya que podrás seguir comunicándote con ellos sin interrupciones.
      </p>
      <p>
        Nuestro equipo de atención al cliente estará disponible para guiarte en todo el proceso de portabilidad y
        responder a todas tus consultas. Te proporcionaremos la información necesaria y los pasos a seguir para que
        puedas realizar el cambio de forma rápida y sin complicaciones.
      </p>
      <p>
        Una vez que hayas completado el procedimiento de portabilidad, podrás disfrutar de los increíbles beneficios y
        comodidades que ofrecen nuestros planes móviles y de hogar. Conectividad de alta calidad, velocidad de
        navegación en la red 5G y atención personalizada son solo algunas de las ventajas que obtendrás al unirte a la
        familia Movistar.
      </p>
      <p>
        No dejes pasar esta oportunidad de mejorar tu experiencia de comunicación y disfrutar de todas las ventajas que
        te brinda Movistar. ¡Realiza la portabilidad y comienza a vivir la mejor conectividad!
      </p>
      <h2 class="colorMovistar fs-3 ">
        Servicios de roaming
      </h2>

      <p>
        Cuando contratas los planes de Movistar, contarás con servicios de roaming que te permitirán mantener tu
        conectividad incluso cuando viajes fuera de Chile. Con el roaming de Movistar, podrás seguir utilizando tu
        teléfono móvil en diferentes países sin perder la comunicación con tus seres queridos o la conexión a Internet.
      </p>

      <p>
        El roaming de Movistar te permite realizar y recibir llamadas, enviar mensajes de texto y navegar por Internet
        con total comodidad y seguridad en aquellos países donde Movistar tiene acuerdos de roaming. Esto significa que
        podrás disfrutar de los mismos beneficios y servicios que tienes en Chile, sin preocuparte por cargos
        adicionales o sorpresas en tu factura.
      </p>

      <p>
        Además, los planes de roaming de Movistar cuentan con una amplia cobertura en lugares considerados, asegurando
        que puedas disfrutar de una conexión estable y de calidad en tus viajes. Podrás estar conectado en diferentes
        destinos, ya sea que estés de vacaciones, en un viaje de negocios o explorando nuevos lugares.
      </p>

      <p>
        No importa si necesitas hacer una llamada importantísima o enviar un mensaje urgente mientras estás de viaje,
        con los servicios de roaming de Movistar tendrás la tranquilidad de estar siempre conectado. Podrás aprovechar
        al máximo tus planes y beneficios sin interrupciones, sin importar dónde te encuentres.
      </p>

      <h2 class="colorMovistar fs-3">
        Promociones y ofertas de Movistar
      </h2>

      <p>
        Movistar ofrece a sus clientes una gran variedad de promociones y ofertas en sus planes 5G. Estas promociones
        están diseñadas para brindar beneficios exclusivos y atractivos descuentos a los usuarios que elijan sus
        servicios.
      </p>

      <p>
        Entre las promociones más destacadas, se encuentran:
      </p>

      <ul>
        <li>
          Promoción de lanzamiento: al contratar un plan 5G de Movistar, los clientes pueden beneficiarse de un
          descuento especial durante los primeros meses de servicio.
        </li>
        <li>
          Ofertas por tiempo limitado: periódicamente, Movistar lanza ofertas exclusivas en sus planes 5G, con
          descuentos adicionales o beneficios adicionales.
        </li>
        <li>
          Regalos y premios: Movistar también organiza sorteos y promociones en las que los clientes pueden participar
          para tener la oportunidad de ganar regalos o premios especiales.
        </li>
      </ul>

      <p>
        Además de estas promociones, Movistar también ofrece descuentos y beneficios exclusivos para aquellos clientes
        que decidan contratar servicios adicionales, como planes de internet en el hogar o servicios de televisión por
        cable.
      </p>

      <p>
        Es importante mencionar que las promociones y ofertas pueden variar según la disponibilidad y la región, por lo
        que se recomienda consultar en la página oficial de Movistar para obtener información actualizada sobre las
        promociones vigentes en cada momento.
      </p>

      <h2 class="colorMovistar fs-3">
        Gestión de datos y tráfico
      </h2>

      <p>
        En los planes de Movistar, la gestión de datos y tráfico es un aspecto fundamental para garantizar una
        experiencia óptima de navegación. Movistar utiliza tecnología de vanguardia para optimizar la velocidad y
        eficiencia de la red, dándote la tranquilidad de que tus datos serán gestionados de manera eficiente.
      </p>
      <p>
        Con los planes 5G de Movistar, puedes disfrutar de una navegación más rápida y fluida. La red 5G te permite
        descargar contenido en cuestión de segundos, realizar streaming de video en alta definición sin interrupciones y
        jugar en línea sin retrasos. Sin embargo, es importante tener en cuenta que el uso de datos puede agotar tu plan
        rápidamente.s
      </p>

      <ul>
        <li>
          Para gestionar tus datos de manera eficiente, Movistar te ofrece herramientas como la opción de recibir
          alertas cuando estés cerca de agotar tu plan de datos.
        </li>
        <li>
          Puedes consultar el consumo de tus datos en cualquier momento a través de la aplicación móvil de Movistar o en
          la página web.
        </li>
        <li>
          Si necesitas más datos, puedes contratar paquetes adicionales para evitar excederte en tu plan principal.
        </li>
        <li>
          Además, Movistar te brinda la opción de reducir la velocidad de tu conexión una vez que hayas agotado tu plan
          de datos, para que puedas seguir navegando sin interrupciones a una menor velocidad.
        </li>
      </ul>

      <p>
        La gestión de tráfico también es importante para garantizar la calidad de servicio en la red de Movistar. Se
        implementan medidas para evitar congestiones y asegurar que todos los usuarios puedan disfrutar de una conexión
        estable y de calidad.
      </p>


      <h2 class="colorMovistar fs-3">
        Giga libre ultra en los planes Movistar
      </h2>

      <p>
        Los planes 5G de Movistar ofrecen una opción llamada 'Giga libre ultra', que brinda a los usuarios una
        experiencia óptima de navegación. Con este plan, podrás disfrutar de una gran cantidad de gigas para aprovechar
        al máximo la velocidad y la conexión de la red 5G.
      </p>

      <p>
        El Giga libre ultra te permite navegar sin preocupaciones y sin límites. Tendrás la libertad de explorar la web,
        disfrutar de tus aplicaciones favoritas y mantener una conectividad constante sin preocuparte por el consumo de
        datos. Esto significa que podrás descargar contenido, ver videos en alta definición y disfrutar de streaming de
        música y películas sin interrupciones.
      </p>

      <p>
        Con la opción de giga libre ultra en los planes Movistar, tendrás la tranquilidad de estar siempre conectado y
        disfrutar de todas las ventajas de la red 5G sin restricciones. Ya no tendrás que preocuparte por el agotamiento
        de tus gigas, ya que contarás con una cantidad generosa para que puedas utilizar tus dispositivos móviles con
        total libertad.
      </p>

      <p>Además, este plan también incluye roaming ilimitado en ciertos países, por lo que podrás disfrutar de todos los
        beneficios de la red 5G incluso cuando te encuentres fuera de Chile. Esto es especialmente útil si viajas con
        frecuencia o si necesitas mantener una conexión estable durante tus viajes internacionales.</p>

      <h2 class="colorMovistar fs-3">
        Roaming en lugares considerados
      </h2>

      <p>
        Los planes 5G de Movistar ofrecen a sus usuarios la posibilidad de disfrutar del servicio de roaming en una
        amplia variedad de lugares. Esto significa que podrás utilizar tu línea telefónica y disfrutar de los beneficios
        de la red 5G incluso cuando te encuentres en otros países.
      </p>
      <p>
        Gracias al roaming ilimitado en ciertos países, podrás realizar llamadas, enviar mensajes de texto y navegar por
        Internet con la misma velocidad y calidad que tendrías en Chile. Esto es especialmente útil cuando viajas por
        trabajo o placer, ya que te mantendrás conectado sin preocuparte por los costos adicionales por uso de datos o
        llamadas internacionales.
      </p>
      <p>
        Los planes 5G de Movistar te brindan la tranquilidad de saber que tendrás la misma experiencia conectada, sin
        importar el lugar del mundo en el que te encuentres. Además, podrás aprovechar todas las ventajas de la red 5G,
        como una mayor velocidad de navegación y una calidad de conexión superior.
      </p>

      <ul>
        <li>
          Disfruta de llamadas ilimitadas en lugares considerados.
        </li>
        <li>
          Navega por Internet sin restricciones en lugares considerados. </li>
        <li>
          Envía mensajes de texto sin límites en lugares considerados. </li>
        <li>
          Mantente conectado en todos tus viajes sin preocuparte por los costos adicionales.
        </li>
      </ul>

      <p>
        Con los planes 5G de Movistar, podrás disfrutar de una experiencia conectada y un servicio de roaming de calidad
        en lugares considerados, permitiéndote estar siempre conectado y disfrutar de todos los beneficios de la red 5G,
        sin importar dónde te encuentres.
      </p>

      <h2 class="colorMovistar fs-3">
        Internet libre en los planes Movistar
      </h2>

      <p>
        En los planes de Movistar, contarás con la ventaja de tener acceso a Internet de manera libre y sin
        restricciones. Esto significa que podrás disfrutar de una navegación fluida y rápida sin preocuparte por
        limitaciones en el uso de tus datos.
      </p>

      <p>
        La opción de Internet libre en los planes de Movistar te brinda la libertad de explorar y disfrutar de todo el
        contenido que desees en línea. Podrás navegar por tus redes sociales, reproducir videos en alta calidad,
        descargar aplicaciones y mucho más, sin preocuparte por agotar tu límite de datos.
      </p>
      <p>
        Con la velocidad 5G que ofrece Movistar, la experiencia de navegación será más fluida que nunca. Podrás
        disfrutar de una conexión de alta velocidad para realizar tus actividades en línea sin interrupciones y con
        mayor rapidez.
      </p>
      <p>
        Ya sea que utilices tu smartphone, tablet o cualquier otro dispositivo compatible, los planes de Movistar te
        brindarán la libertad de estar conectado en cualquier momento y lugar. Podrás mantenerte actualizado, compartir
        momentos con tus seres queridos y estar al tanto de todo lo que sucede a tu alrededor.
      </p>
      <p>
        Además, con la amplia cobertura de Movistar a lo largo de Chile, podrás disfrutar de Internet libre en
        prácticamente cualquier lugar del país. Ya sea que estés en la ciudad, en la playa o en la montaña, tendrás la
        tranquilidad de contar con una conexión estable y rápida.s
      </p>

      <ul>
        <li>
          Acceso a Internet sin restricciones
        </li>
        <li>
          Navegación fluida y rápida
        </li>
        <li>
          Compatibilidad con dispositivos móviles y tablets
        </li>
        <li>
          Velocidad 5G para una experiencia de navegación superior
        </li>
        <li>
          Cobertura amplia en todo Chile
        </li>
      </ul>

      <h2 class="colorMovistar fs-3">
        Beneficios exclusivos para los usuarios de Movistar-Chile
      </h2>

      <p>
        Los usuarios de Movistar-Chile que optan por los planes 5G de la compañía pueden disfrutar de una serie de
        beneficios exclusivos que mejorarán su experiencia de conectividad:
      </p>

      <ul>
        <li>
          Acceso prioritario a la red 5G: Los usuarios de Movistar-Chile tendrán acceso prioritario a la red 5G, lo que
          les permitirá disfrutar de una mayor velocidad de navegación y una experiencia conectada más fluida.
        </li>
        <li>
          Descuentos especiales en equipos: Al contratar un plan 5G de Movistar, los usuarios de Movistar-Chile podrán
          disfrutar de descuentos especiales en la adquisición de equipos móviles de última generación, permitiéndoles
          estar a la vanguardia de la tecnología. </li>
        <li>
          Servicio de atención al cliente personalizado: Movistar-Chile ofrece un servicio de atención al cliente
          personalizado para sus usuarios de planes 5G, brindando asesoramiento y soporte técnico especializado para
          garantizar una experiencia satisfactoria en todo momento. </li>
        <li>
          Exclusivas promociones y ofertas: Los usuarios de Movistar-Chile que elijan los planes 5G podrán acceder a
          promociones y ofertas exclusivas en servicios adicionales como streaming de contenido, plataformas de música
          en línea y servicios de seguridad digital. </li>
        <li>
          Programa de fidelidad: Movistar-Chile cuenta con un programa de fidelidad para sus usuarios de planes 5G,
          donde podrán acumular puntos canjeables por descuentos, regalos o beneficios adicionales.s </li>
      </ul>

      <p>
        Estos beneficios exclusivos están diseñados para brindar a los usuarios de Movistar-Chile una experiencia única
        y satisfactoria en su conexión 5G, ofreciendo ventajas adicionales que van más allá de una simple conectividad
        de alta velocidad.
      </p>

    </section>

  </div>

  <div *ngIf="this.router.url.includes('/Planes2x')">
    <section>
      <div class="container mt-3">
        <div class="row d-flex justify-content-center">
          <div class="d-flex justify-content-between justify-content-lg-center gap-md-4">
            <div class="button-nav text-nowrap px-2 " [routerLink]="['/movil/PlanesIndividuales']">Planes Individuales
            </div>
            <div class="button-nav text-nowrap px-2 " [routerLink]="['/movil/Planes2x']">Pack 2x</div>
          </div>

          <div class="col-12 text-center my-4">
            <h1 class="text-black fw-light"><span class="colorMovistar fw-bold">Los mejores Planes Móviles</span> y
              Ofertas
              Portabilidad</h1>
            <p class="h4 mt-4">

            </p>
          </div>


          <div class="col-12 col-sm-4 col-md-3 col-lg-3 my-2" *ngFor="let product of productos">
            <app-caluga [type]="'movil'" [producto]="product"></app-caluga>
          </div>
        </div>
      </div>
    </section>
    <section class="container">

    </section>
    <section class="container bg-light mt-5 py-4">
      <div class="container">
        <h2 class="fw-bold colorMovistar mb-4">Nuestros Planes incluyen</h2>
      </div>
      <div class="row d-flex flex-column flex-md-row">
        <div class="col-12 col-md-4">
          <h4 class="text-black mb-2">Internet</h4>
          <div class="row">
            <div class="col-12 d-flex gap-4 align-items-center mb-4">
              <img src="../../../assets/img/Iconos Landing/Gigas.svg" height="40" width="40">
              <div>
                <span class="fw-bold text-black mb-0">Gigas Libres</span>
              </div>
            </div>
            <div class="col-12 d-flex gap-4 align-items-center mb-4">
              <img src="../../../assets/img/Iconos Landing/roaming.svg" height="40" width="40" fill="#00a9e0;">
              <div>
                <span class="fw-bold text-black mb-0">Roaming por 7 días desde</span>
                <p class="text-black mb-0 fs08">Plan 5G libre full</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <h4 class="text-black mb-2">Streaming</h4>
          <div class="row">
            <div class="col-12 d-flex gap-4 align-items-center mb-4">
              <img src="../../../assets/img/Iconos Landing/movistar_play.svg" alt="movistar_play" height="40"
                width="40">
              <div>
                <span class="fw-bold text-black mb-0">Movistar TV Free</span>
                <p class="text-black mb-0 fs08">Entretención para nunca aburrirse.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


  </div>

</div>